
#video-holder{
	display: inline-block;
	background: #808080;
	border-style: inset;
	vertical-align: top;
}

.center-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}