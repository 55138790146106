#pollOption{
	border-style: outset;
	margin-left: 15px;
	margin-right: 15px;
	background-color: rgb(211, 211, 211);
	padding: 2px;
	justify-content: center;
	align-items: center;
	flex: 1;
	height: auto;
	display: flex;
	font-size: 20px;
}

#clicked{
	border-style: inset;
	margin-left: 15px;
	margin-right: 15px;
	cursor: pointer;
	background-color: rgb(105, 105, 105);
	padding: 2px;
	flex: 1;
	align-items: center;
	justify-content: center;
	height: auto;
	display: flex;
	color: white;
	font-size: 20px;
}

#pollOption:hover{
	border-style: outset;
	margin-left: 15px;
	margin-right: 15px;
	color: black;
	cursor: pointer;
	background-color: #6699cc;
	padding: 2px;
	flex: 1;
    align-items: center;
    justify-content: center;
	height: auto;
	display: flex;
	font-size: 20px;
}
