#root{
  background-color: black;
}

.App{
	background-color: #dcdcdc;
}

.hidden{
	display: none;
}

.show{
	display: inline;
}