.btn-1{
	display:inline-block;
	padding:0.2em 0.2em;
	margin:0.5em 0.1em 0.5em 0.1em;
	box-sizing: border-box;
	text-decoration:none;
	font-family:'Roboto',sans-serif;
	font-weight:400;
	color:white;
	background-color:#6699cc;
	box-shadow: outset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17);
	text-align:center;
	position:relative;
  }
  
.btn-1:hover{
	display:inline-block;
	padding:0.2em 0.2em;
	margin:0.5em 0.1em 0.5em 0.1em;
	box-sizing: border-box;
	text-decoration:none;
	font-family:'Roboto',sans-serif;
	font-weight:bold;
	color: black;
	background-color:#DBDBDB;
	box-shadow:inset;
	text-align:center;
	position:relative;
	cursor: pointer;
  }