td{
	border-style: inset;
	padding: 2px;
	background-color: rgb(211, 211, 211);
}

th{
	border-style: inset;
	padding: 2px;
	background-color: rgb(45, 52, 62);
	color: white;
}

