
.homepage {
  text-align: center;
  color: white;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 500px;
  height: 500px;
  padding: 2rem;
  background-color: #2d343e;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 5px;
  input {
    height: 50px;
    width: 80%;
    background-color: #404450;
    border: none;
    padding-left: 1rem;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
  }

}
