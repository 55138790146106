input[type=text].message{
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
  left: auto !important; 
  top: auto !important; 
  background-color: white;
  align-items: center;
  justify-content: center;
}