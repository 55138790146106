.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    background-color: orange ;
  }
}

input[type=text].subchat {
	display: inline-block;
	color: white;
	font-size: 10px;
	text-align: left;
	padding: 5px;
	width: 100%;
	margin: 0px;
	
}
