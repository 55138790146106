.EditTitle{
	color: black;
	font-size: 12px;
	text-align: center;
	margin: 0px; 
	font-weight: bold;
}

#oDC{
	margin: 5px;
	overflow-y: hidden;
}

input[type=text] {
	color: black;
	font-size: 12px;
	margin: 5px;
	text-align: center;
	background: transparent;
	border: 0;
	font-weight: bold;
	overflow-y: hidden;
}
