.pollOption{
	border-style: outset;
	margin-left: 15px;
	margin-right: 15px;
	height: 15%;
	background-color: white;
	padding: 2px;
	left: auto !important; 
	top: auto !important; 
}

.pollOption:hover{
	border-style: outset;
	margin-left: 15px;
	margin-right: 15px;
	height: 15%;
	cursor: pointer;
	background-color: #6699cc;
	padding: 2px;
	left: auto !important; 
	top: auto !important; 
}  
  
