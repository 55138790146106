.attendeeList{
	margin: 10px;
	width: 80%;
	background-color: #D3D3D3;
	padding-left: 2px;
	height: 40%;
	border-style: inset;
	padding: 10px;
}

.dropRow{
	min-height: 20px;
}

#attendeeRow{
	border-style: inset;
	margin: 10px;
	margin-left: 2px;
	margin-right: 2px;
	padding: 2px;
	background-color: #BDBDBD;

}

#heading{
	color: white;
	border-style: inset;
	margin-left: 2px;
	margin-right: 2px;
	background-color: #2d343e;
	text-align: center;
	padding: 1px;
	
	
}
