input[type=text] {
	color: white;
	font-size: 12px;
	text-align: center;
	padding: 0;
	width: 70px;
}
.card{
	background-color: #BDBDBD;
}
h3{
	font-size: small;
	color: rgb(255,1,255);
	
}
	
#cardHolder{
	align-items: center;
	justify-content: center;
	display:flex; 
	flex-direction: column;
	margin: 10px;
}

.copyMode{
	background-color: yellow;
}

.selectedMode{
	background-color: white;
}

.normalMode{
	
	
}